import { defineComponent, ref, onMounted, watch } from 'vue';
export default defineComponent({
  name: 'EeTrainRouteStationSelect',
  emits: ['initSuccess'],
  props: {
    defaultData: {
      type: Array,
      default: []
    },
    initMethod: {
      type: Function
    },
    initParams: {
      type: Object
    }
  },

  setup(props, {
    emit,
    expose
  }) {
    const stations = ref([]);
    const hasData = ref(false);

    const init = () => {
      if (props.defaultData.length > 0) {
        stations.value = props.defaultData;
      } else {
        if (props.initParams.trainId) {
          props.initMethod && props.initMethod({ ...props.initParams
          }).then($stations => {
            stations.value = $stations || [];
            hasData.value = stations.value.length > 0 ? true : false;
            emit('initSuccess', $stations);
          });
        }
      }
    };

    onMounted(init);
    watch(props.initParams, () => {
      init();
    });
    expose({
      hasData
    });
    return {
      stations
    };
  }

});