import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");

  const _component_el_option = _resolveComponent("el-option");

  const _component_el_option_group = _resolveComponent("el-option-group");

  const _component_el_select = _resolveComponent("el-select");

  return _openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.model = $event),
    loading: _ctx.loading,
    multiple: "",
    "collapse-tags": "",
    "collapse-tags-tooltip": "",
    "collapse-tags-tooltip-teleported": "",
    clearable: "",
    onChange: _ctx.handleChange,
    onVisibleChange: _ctx.handleVisibleChange,
    long: "",
    class: _normalizeClass(['ee-goods-select', {
      'ee-goods-select__single-selected': _ctx.model && _ctx.model.length > 1
    }])
  }, {
    default: _withCtx(() => [_createVNode(_component_el_option, {
      value: 0,
      disabled: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        ref: "inputRef",
        placeholder: "输入货物名称或拼音搜索",
        modelValue: _ctx.keyword,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.keyword = $event),
        onInput: _cache[1] || (_cache[1] = $event => _ctx.load(_ctx.keyword))
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_option_group, {
      label: "搜索结果"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodses, goods => {
        return _openBlock(), _createBlock(_component_el_option, {
          value: goods.id,
          label: goods.name
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(goods?.category?.name) + "-" + _toDisplayString(goods?.name), 1)]),
          _: 2
        }, 1032, ["value", "label"]);
      }), 256))]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "loading", "onChange", "onVisibleChange", "class"]);
}