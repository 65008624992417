import { defineComponent, ref, reactive } from 'vue';
export default defineComponent({
  name: 'EeReasonPopover',
  emits: ['confirm'],

  setup(props, {
    emit
  }) {
    const formRef = ref();
    const form = reactive({});
    const submiting = ref(false);
    return {
      formRef,
      form,
      submiting,

      handleClick() {
        formRef.value.validate(valid => {
          if (valid) {
            submiting.value = true;
            Promise.resolve(emit('confirm', form.reason)).finally(() => submiting.value = false);
          }
        });
      }

    };
  }

});