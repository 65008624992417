import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "print-view-area-page"
};
const _hoisted_2 = {
  class: "print-view-area-page-header"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  class: "print-view-area-page-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");

  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");

  const _component_el_descriptions = _resolveComponent("el-descriptions");

  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, page => {
    return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.allowDangerous ? (_openBlock(), _createElementBlock("label", {
      key: 0,
      class: "print-view-area-page-header-title",
      style: {
        "padding-top": "10px"
      },
      innerHTML: _ctx.title
    }, null, 8, _hoisted_3)) : _createCommentVNode("", true)]), _createElementVNode("div", {
      class: "print-view-area-page-data",
      style: _normalizeStyle(`height:${_ctx.portrait ? 250.5 : 184.5}mm`)
    }, [_renderSlot(_ctx.$slots, "page", {
      currentPage: page,
      pageIndex: page - 1
    })], 4), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_divider), _createVNode(_component_el_descriptions, null, {
      default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
        width: "45%",
        "label-align": "left",
        label: "成都(双流)空铁国际联运港·综合信息服务系统"
      }), _createVNode(_component_el_descriptions_item, {
        width: "10%",
        align: "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(page) + "/" + _toDisplayString(_ctx.totalPages), 1)]),
        _: 2
      }, 1024), _createVNode(_component_el_descriptions_item, {
        width: "45%",
        align: "right"
      }, {
        default: _withCtx(() => [_createTextVNode("成都空港现代服务业发展有限公司")]),
        _: 1
      })]),
      _: 2
    }, 1024)])]);
  }), 256);
}