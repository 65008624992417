import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'EeBooleanTag',
  props: {
    value: {
      type: Boolean
    },
    trueText: {
      type: String,
      default: '是'
    },
    trueType: {
      type: String,
      default: 'success'
    },
    falseText: {
      type: String,
      default: '否'
    },
    falseType: {
      type: String,
      default: 'danger'
    }
  },

  setup(props) {
    return {};
  }

});