import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = ["title"];
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "el-upload-list__item-actions"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "file-name is-align-middle"
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  key: 2
};
const _hoisted_9 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_eli_plus = _resolveComponent("eli-plus");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_eli_delete = _resolveComponent("eli-delete");

  const _component_el_alert = _resolveComponent("el-alert");

  const _component_el_upload = _resolveComponent("el-upload");

  const _component_el_progress = _resolveComponent("el-progress");

  const _component_el_col = _resolveComponent("el-col");

  const _component_el_button = _resolveComponent("el-button");

  const _component_el_row = _resolveComponent("el-row");

  const _component_el_dialog = _resolveComponent("el-dialog");

  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.dialogVisible = $event),
    title: "文件上传",
    width: "50%",
    "custom-class": "file-upload-dialog",
    onClose: _cache[3] || (_cache[3] = $event => _ctx.handleCloseDialog())
  }, {
    footer: _withCtx(() => [_createVNode(_component_el_row, {
      justify: "space-between"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 18
      }, {
        default: _withCtx(() => [_ctx.uploading ? (_openBlock(), _createBlock(_component_el_progress, {
          key: 0,
          percentage: _ctx.uploadPercentage,
          status: "success",
          "stroke-width": 10
        }, {
          default: _withCtx(() => [_ctx.uploadPercentage == 0 ? (_openBlock(), _createElementBlock("span", _hoisted_6, "即将开始上传")) : _ctx.uploadPercentage == 100 ? (_openBlock(), _createElementBlock("span", _hoisted_7, "上传完成，数据处理中……")) : (_openBlock(), _createElementBlock("span", _hoisted_8, "已上传" + _toDisplayString(_ctx.uploadPercentage) + "%", 1))]),
          _: 1
        }, 8, ["percentage"])) : _createCommentVNode("", true)]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 6
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_9, [_createVNode(_component_el_button, {
          size: "small",
          onClick: _cache[0] || (_cache[0] = $event => _ctx.handleCloseDialog()),
          loading: _ctx.uploading
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }, 8, ["loading"]), _createVNode(_component_el_button, {
          size: "small",
          type: "primary",
          disabled: _ctx.uploadDisabled,
          loading: _ctx.uploading,
          onClick: _cache[1] || (_cache[1] = $event => _ctx.handleUpload())
        }, {
          default: _withCtx(() => [_createTextVNode(" 上传 ")]),
          _: 1
        }, 8, ["disabled", "loading"])])]),
        _: 1
      })]),
      _: 1
    })]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), _createVNode(_component_el_upload, {
      ref: "uploadRef",
      "list-type": "picture-card",
      multiple: !_ctx.limit || _ctx.limit > 1 ? true : false,
      limit: _ctx.limit,
      action: `${_ctx.$http.defaults.baseURL}${_ctx.action}`,
      data: { ..._ctx.actionData
      },
      "auto-upload": false,
      "show-file-list": true,
      disabled: _ctx.uploadDisabled,
      "on-exceed": _ctx.handleExceed,
      "on-change": _ctx.fileChange,
      "on-success": _ctx.uploadSuccess,
      "on-error": _ctx.uploadError,
      "on-progress": _ctx.handleProgress
    }, {
      file: _withCtx(({
        file
      }) => [_createElementVNode("div", {
        style: {
          "width": "100%"
        },
        title: file.name
      }, [_createElementVNode("img", {
        class: "el-upload-list__item-thumbnail",
        src: _ctx.imgSrc(file),
        alt: "img_thumb"
      }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", {
        class: "el-upload-list__item-delete",
        onClick: $event => _ctx.handleRemove(file)
      }, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_eli_delete)]),
        _: 1
      })], 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(file.name), 1)])], 8, _hoisted_1)]),
      tip: _withCtx(() => [_ctx.limit ? (_openBlock(), _createBlock(_component_el_alert, {
        key: 0,
        title: `最多上传${_ctx.limit}个文件`,
        closable: false,
        type: "info"
      }, null, 8, ["title"])) : _createCommentVNode("", true), _ctx.uploadDisabled ? (_openBlock(), _createBlock(_component_el_alert, {
        key: 1,
        title: `文件类型错误，允许上传的文件类型有:${_ctx.acceptExts}`,
        closable: false,
        type: "error"
      }, null, 8, ["title"])) : _createCommentVNode("", true)]),
      default: _withCtx(() => [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_eli_plus)]),
        _: 1
      })]),
      _: 1
    }, 8, ["multiple", "limit", "action", "data", "disabled", "on-exceed", "on-change", "on-success", "on-error", "on-progress"])]),
    _: 3
  }, 8, ["modelValue"]);
}