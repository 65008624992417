import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  return _openBlock(), _createBlock(_component_el_select, {
    placeholder: "请选择箱型",
    loading: _ctx.loading,
    long: ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.containerCategorys, cc => {
      return _openBlock(), _createBlock(_component_el_option, {
        label: cc.name,
        value: cc.id,
        key: cc.id
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["loading"]);
}