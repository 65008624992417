import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  name: 'EeFilterDetail',
  emits: ['change', 'close'],
  props: {
    // 查询表单ref
    formRef: {
      type: Object,
      required: true
    },
    // 查询条件对象
    filters: {
      type: Object,
      required: true
    },
    // 使用此组件时，存在组件不能自动获取查询输入框显示文本时，请使用查询值格式化器
    // 查询值格式化器，用form-item的prop属性做对象名称，例：{name:(value)=>{}}
    formatter: {
      type: Object
    },
    labelWidth: {
      type: Number,
      default: 80
    }
  },

  setup(props, {
    emit
  }) {
    const fiterTags = ref([]);
    const formItems = ref([]);

    const init = () => {
      const items = props.formRef.$.ctx.formItems;
      items.forEach(item => {
        const props = item.props;
        const label = props.label.replace(/\s*/g, '');
        const prop = props.prop;
        const inputs = item.refs.formItemRef.querySelectorAll('.el-input__inner');
        formItems.value.push({
          prop,
          label,
          inputs
        });
      });
    };

    const getValue = () => {
      if (formItems.value.length == 0) {
        init();
      }

      setTimeout(() => {
        fiterTags.value = [];
        formItems.value.forEach(formItem => {
          let values = [];
          formItem.inputs.forEach(input => {
            values.push(input.value);
          });
          const queryValue = props.filters[formItem.prop];
          const tmpValue = values.join();
          const showValue = tmpValue != '' && tmpValue.replaceAll(',', '') != '' ? tmpValue : null;

          if (queryValue != null && queryValue.toString().replaceAll(',', '') != '') {
            const value = showValue ? showValue : queryValue;

            if (value != null) {
              fiterTags.value.push({
                prop: formItem.prop,
                label: formItem.label,
                value: value
              });
            }
          }
        });
      }, 10);
    };

    watch(() => props.formRef, () => {
      init();

      if (props.filters) {
        getValue();
      }
    });
    watch(props.filters, () => {
      getValue();
    });
    return {
      width: props.labelWidth,
      fiterTags,

      handleClose(tag) {
        const prop = tag.prop;

        if (props.filters[prop]) {
          props.filters[prop] = null;
          emit('change', props.filters);
        }

        emit('close', tag);
      },

      formatterValue(prop, value) {
        if (props.formatter && props.formatter[prop]) {
          return props.formatter[prop](props.filters[prop]);
        }

        return value;
      }

    };
  }

});