import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  return _openBlock(), _createBlock(_component_el_select, {
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    placeholder: "输入城市名称搜索",
    "remote-method": _ctx.handleRemoteMethod,
    loading: _ctx.loading,
    onChange: _ctx.handleChange,
    long: ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.areas, area => {
      return _openBlock(), _createBlock(_component_el_option, {
        value: area.id,
        label: area.name
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(area?.name), 1)]),
        _: 2
      }, 1032, ["value", "label"]);
    }), 256))]),
    _: 1
  }, 8, ["remote-method", "loading", "onChange"]);
}