import { defineComponent, ref, onMounted, computed, watch } from 'vue';
export default defineComponent({
  name: 'EeTrainLineCategoryCascader',
  emits: ['update:modelValue', 'update:lineCategoryId', 'update:lineId', 'update:lineCategoryIds', 'update:lineIds', 'update:selectedText'],
  props: {
    modelValue: {},
    loader: {
      type: Function
    },
    lineCategoryId: {
      type: Number
    },
    lineId: {
      type: Number
    },
    lineCategoryIds: {
      type: Array
    },
    lineIds: {
      type: Array
    },
    multi: {
      type: Boolean,
      default: false
    },
    selectedText: {
      type: String
    }
  },

  setup(props, {
    emit
  }) {
    const options = ref([]);
    const tmpValue = ref(); //const selectedText = ref('');

    const value = computed({
      set(val) {
        if (props.multi) {
          handleMulti(val);
        } else {
          emit('update:lineCategoryId', val ? val[0] : null);
          emit('update:lineId', val ? val[1] : null);
        }

        emit('update:modelValue', val);
        tmpValue.value = val;
        setSelectedText(val);
      },

      get() {
        return props.modelValue || tmpValue.value;
      }

    });

    const handleMulti = val => {
      let tempCategory = [];
      let tempLine = [];

      if (val && val.length > 0) {
        val.forEach(ele => {
          if (tempCategory.indexOf(ele[0]) < 0) {
            tempCategory.push(ele[0]);
          }

          if (ele.length > 1 && tempLine.indexOf(ele[1]) < 0) {
            tempLine.push(ele[1]);
          }
        });
      }

      emit('update:lineCategoryIds', tempCategory);
      emit('update:lineIds', tempLine);
    };

    const setSelectedText = vals => {
      let labels = [];

      if (vals) {
        if (props.multi) {
          vals.forEach(val => {
            const label = getOptionsLabel(val[0], val[1]);

            if (label) {
              labels.push(label);
            }
          });
        } else {
          const label = getOptionsLabel(vals[0], vals[1]);

          if (label) {
            labels.push(label);
          }
        }

        emit('update:selectedText', labels.join());
      } else {
        emit('update:selectedText', '');
      }
    };

    const getOptionsLabel = (categoryId, lineId) => {
      let label = '';

      if (categoryId) {
        const category = options.value.find(val => val.value == categoryId);
        label = category.label;

        if (lineId) {
          const line = category.children.find(val => val.value == lineId);
          label = label + '/' + line.label;
        }
      }

      return label;
    };

    watch([() => props.modelValue], () => {
      if (props.modelValue != value.value) {
        value.value = props.modelValue;
      }
    });

    const init = () => {
      props.loader && props.loader().then($data => options.value = $data);
    };

    onMounted(init);
    return {
      value,
      options
    };
  }

});