import http from '@chinamo/http';
import { createStore } from 'vuex';
import createPersistedstate from 'vuex-persistedstate';

const store = createStore({
  state: {
    token: undefined,
    expires: undefined
  },
  mutations: {
    setTokenCache(state, { token, expires }) {
      state.token = token;
      state.expires = expires;
    },
    clearCache(state) {
      state.token = undefined;
      state.expires = undefined;
    }
  },
  plugins: [
    createPersistedstate({
      key: 'TOKEN-REFRESH'
    })
  ]
});

http.defaults.withCredentials = false;
http.defaults.xsrfCookieName = undefined;

const $config = {};

export default {
  init(config) {
    Object.assign($config, config);
  },
  clear() {
    store.commit('clear');
  },
  async refresh() {
    const currentTime = new Date().getTime();
    const { token, expires } = store.state;
    if (token && expires && expires > currentTime) {
      return token;
    } else if ($config.refreshUrl) {
      return await http.get($config.refreshUrl, { withoutToken: true }).then(({ token: $token, maxAge }) => {
        const endTime = new Date().getTime();
        const diffTime = endTime - currentTime;
        store.commit('setTokenCache', { token: $token, expires: endTime + maxAge * 1000 - diffTime - 5000 });
        return $token;
      });
    }
  }
};
