import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag");

  const _component_el_col = _resolveComponent("el-col");

  const _component_el_row = _resolveComponent("el-row");

  return _openBlock(), _createBlock(_component_el_row, {
    align: "middle"
  }, {
    default: _withCtx(() => [_ctx.fiterTags.length > 0 ? (_openBlock(), _createElementBlock("label", {
      key: 0,
      class: "ee-filter-detail__label el-form-item__label",
      style: _normalizeStyle({
        width: _ctx.width + 'px'
      })
    }, "查询明细", 4)) : _createCommentVNode("", true), _createVNode(_component_el_col, {
      style: _normalizeStyle({
        paddingLeft: _ctx.width + 'px'
      })
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fiterTags, tag => {
        return _openBlock(), _createBlock(_component_el_tag, {
          closable: "",
          type: "info",
          onClose: $event => _ctx.handleClose(tag),
          class: "ee-filter-detail__tag"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(tag.label) + "：" + _toDisplayString(_ctx.formatterValue(tag.prop, tag.value)), 1)]),
          _: 2
        }, 1032, ["onClose"]);
      }), 256))]),
      _: 1
    }, 8, ["style"])]),
    _: 1
  });
}