import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");

  const _component_el_row = _resolveComponent("el-row");

  const _component_el_empty = _resolveComponent("el-empty");

  const _component_el_carousel_item = _resolveComponent("el-carousel-item");

  const _component_el_carousel = _resolveComponent("el-carousel");

  const _component_el_dialog = _resolveComponent("el-dialog");

  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.dialogVisible = $event),
    title: `文件预览：${_ctx.currentFilename}`,
    "append-to-body": true,
    "custom-class": "preview-dialog",
    width: "80%",
    top: "3vh",
    onOpened: _cache[1] || (_cache[1] = $event => _ctx.handleOpenedDialog()),
    onClose: _cache[2] || (_cache[2] = $event => _ctx.handleCloseDialog())
  }, {
    default: _withCtx(() => [_createVNode(_component_el_carousel, {
      ref: "carouselRef",
      "initial-index": _ctx.active,
      autoplay: false,
      "indicator-position": "none",
      arrow: "always",
      onChange: _ctx.handleCarouselChange
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previews, preview => {
        return _openBlock(), _createBlock(_component_el_carousel_item, null, {
          default: _withCtx(() => [_ctx.imageSuffix.includes(preview.suffix) ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            justify: "center",
            align: "middle",
            style: {
              "height": "100%",
              "background-color": "#e6e8eb"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_image, {
              id: `${preview.id}`,
              src: null,
              fit: "contain",
              style: {
                "width": "90%",
                "height": "90%"
              }
            }, null, 8, ["id"])]),
            _: 2
          }, 1024)) : preview.suffix === 'pdf' ? (_openBlock(), _createElementBlock("iframe", {
            key: 1,
            title: "pdf",
            id: `${preview.id}`,
            src: null,
            height: "100%",
            width: "100%",
            style: {
              "border": "none"
            }
          }, null, 8, _hoisted_1)) : (_openBlock(), _createBlock(_component_el_empty, {
            key: 2,
            description: "当前文件暂时不支持预览"
          }))]),
          _: 2
        }, 1024);
      }), 256))]),
      _: 1
    }, 8, ["initial-index", "onChange"])]),
    _: 1
  }, 8, ["modelValue", "title"]);
}