import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_cascader = _resolveComponent("el-cascader");

  return _openBlock(), _createBlock(_component_el_cascader, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.value = $event),
    placeholder: "输入线路种类或线路名称搜索",
    props: {
      checkStrictly: true,
      multiple: _ctx.multi
    },
    options: _ctx.options,
    "collapse-tags": "",
    "collapse-tags-tooltip": "",
    filterable: "",
    clearable: ""
  }, {
    default: _withCtx(({
      node,
      data
    }) => [_createElementVNode("span", null, _toDisplayString(data.label), 1), !node.isLeaf ? (_openBlock(), _createElementBlock("span", _hoisted_1, " (" + _toDisplayString(data.children.length) + ") ", 1)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue", "props", "options"]);
}