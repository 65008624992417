import { defineComponent, ref, onMounted } from 'vue';
import print from 'vue3-print-nb';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'EePrintPage',
  directives: {
    print
  },
  props: {
    title: {
      type: String,
      required: true
    },
    totalPages: {
      type: Number,
      required: true,
      default: 1
    },
    showPrinter: {
      type: Boolean,
      default: true
    },
    portrait: {
      type: Boolean,
      default: true
    }
  },

  setup(props) {
    const store = useStore();
    const printer = store.state.profile.name;
    return {
      printer
    };
  }

});