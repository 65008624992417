import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition");

  const _component_ee_filter_detail = _resolveComponent("ee-filter-detail");

  const _component_el_button = _resolveComponent("el-button");

  const _component_el_button_group = _resolveComponent("el-button-group");

  const _component_el_row = _resolveComponent("el-row");

  const _component_el_checkbox = _resolveComponent("el-checkbox");

  const _component_el_col = _resolveComponent("el-col");

  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

  const _component_el_popover = _resolveComponent("el-popover");

  const _component_ee_file_download_button = _resolveComponent("ee-file-download-button");

  const _component_ee_collapse_button = _resolveComponent("ee-collapse-button");

  const _component_el_form = _resolveComponent("el-form");

  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_form, {
    model: _ctx.filters,
    ref: "formRef",
    class: "el-query-form",
    "label-width": "80px"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_collapse_transition, null, {
      default: _withCtx(() => [!_ctx.more ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {
        filters: _ctx.filters
      })])) : _createCommentVNode("", true)]),
      _: 3
    }), _createVNode(_component_ee_filter_detail, {
      formRef: _ctx.formRef,
      "onUpdate:formRef": _cache[0] || (_cache[0] = $event => _ctx.formRef = $event),
      filters: _ctx.filters,
      "onUpdate:filters": _cache[1] || (_cache[1] = $event => _ctx.filters = $event),
      "label-width": 80,
      formatter: _ctx.formatter
    }, null, 8, ["formRef", "filters", "formatter"]), _createVNode(_component_el_row, {
      justify: "space-between"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [_createVNode(_component_el_popover, {
          placement: "bottom-start",
          width: 500,
          trigger: "hover"
        }, {
          reference: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "eli-filter"
          }, {
            default: _withCtx(() => [_createTextVNode("导出列")]),
            _: 1
          })]),
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_button_group, null, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                size: "small",
                icon: "eli-select",
                onClick: _cache[2] || (_cache[2] = $event => _ctx.handleSelectedAll())
              }, {
                default: _withCtx(() => [_createTextVNode("全选")]),
                _: 1
              }), _createVNode(_component_el_button, {
                size: "small",
                icon: "eli-finished",
                onClick: _cache[3] || (_cache[3] = $event => _ctx.handleSelectedDefault())
              }, {
                default: _withCtx(() => [_createTextVNode("默认")]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_checkbox_group, {
            modelValue: _ctx.selectedColumnCodes,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.selectedColumnCodes = $event),
            style: {
              "width": "100%"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              gutter: 20
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, column => {
                return _openBlock(), _createBlock(_component_el_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_checkbox, {
                    label: column.code,
                    checked: column.isDisplay
                  }, {
                    default: _withCtx(() => [_createTextVNode(_toDisplayString(column.label), 1)]),
                    _: 2
                  }, 1032, ["label", "checked"])]),
                  _: 2
                }, 1024);
              }), 256))]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_ee_file_download_button, {
          type: "success",
          icon: "eli-download",
          href: _ctx.downloadHref,
          data: _ctx.filters,
          "onUpdate:data": _cache[5] || (_cache[5] = $event => _ctx.filters = $event),
          disabled: _ctx.countDownTimer.countFlag,
          onBeforeDownload: _ctx.handleBeforeDownload
        }, {
          default: _withCtx(() => [_createTextVNode(" 导出 ")]),
          _: 1
        }, 8, ["href", "data", "disabled", "onBeforeDownload"]), _renderSlot(_ctx.$slots, "tools", {
          filters: _ctx.filters
        })]),
        _: 3
      }), _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [_createVNode(_component_ee_collapse_button, {
          modelValue: _ctx.more,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.more = $event)
        }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
          type: "primary",
          icon: "eli-search",
          onClick: _cache[7] || (_cache[7] = $event => _ctx.load())
        }, {
          default: _withCtx(() => [_createTextVNode("查询")]),
          _: 1
        }), _createVNode(_component_el_button, {
          icon: "eli-refresh",
          onClick: _cache[8] || (_cache[8] = $event => _ctx.load(true))
        }, {
          default: _withCtx(() => [_createTextVNode("重置")]),
          _: 1
        })]),
        _: 1
      })]),
      _: 3
    })]),
    _: 3
  }, 8, ["model"]), _ctx.pagination ? (_openBlock(), _createBlock(_component_el_table, {
    key: 0,
    ref: "tableRef",
    "pagination-loader": _ctx.loader,
    height: "100%"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      type: "index",
      label: "#"
    }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showColumns, column => {
      return _openBlock(), _createBlock(_component_el_table_column, {
        label: column.label,
        "show-overflow-tooltip": "",
        "min-width": 120
      }, {
        default: _withCtx(scope => [_ctx.slots[`${column.code}Format`] ? _renderSlot(_ctx.$slots, `${column.code}Format`, {
          key: 0,
          row: scope.row,
          $index: scope.$index,
          scope: { ...scope
          }
        }) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.objValue(scope.row, column.attr || column.code)), 1))]),
        _: 2
      }, 1032, ["label"]);
    }), 256))]),
    _: 3
  }, 8, ["pagination-loader"])) : (_openBlock(), _createBlock(_component_el_table, {
    key: 1,
    ref: "tableRef",
    data: _ctx.tableData,
    height: "100%"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      type: "index",
      label: "#"
    }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showColumns, column => {
      return _openBlock(), _createBlock(_component_el_table_column, {
        label: column.label,
        "show-overflow-tooltip": "",
        "min-width": 120
      }, {
        default: _withCtx(scope => [_ctx.slots[`${column.code}Format`] ? _renderSlot(_ctx.$slots, `${column.code}Format`, {
          key: 0,
          row: scope.row,
          $index: scope.$index,
          scope: { ...scope
          }
        }) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.objValue(scope.row, column.attr || column.code)), 1))]),
        _: 2
      }, 1032, ["label"]);
    }), 256))]),
    _: 3
  }, 8, ["data"]))], 64);
}