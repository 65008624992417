import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  name: 'EeTrainLineSelect',
  emits: ['change', 'syncCategory'],
  props: {
    defaultData: {
      type: Array,
      default: []
    },
    remoteMethod: {
      type: Function
    },
    categoryId: {
      type: Number,
      default: null
    }
  },

  setup(props, {
    emit
  }) {
    const loading = ref(false);
    const lines = ref([]);

    const load = keyword => {
      props.remoteMethod && props.remoteMethod(keyword, props.categoryId).then($lines => lines.value = $lines || []).finally(() => loading.value = false);
    };

    if (props.defaultData.length > 0) {
      lines.value = props.defaultData;
    } else {
      load(null);
    }

    watch(() => props.defaultData, (newVal, oldVal) => {
      lines.value = newVal;
    });
    return {
      lines,
      loading,
      handleRemoteMethod: keyword => {
        if (keyword) {
          loading.value = true;
          load(keyword);
        } else {
          lines.value = [];
        }
      },
      handelChange: val => {
        emit('change', val, lines.value.find(line => line.id == val));
        emit('syncCategory', lines.value.find(line => line.id == val).category);
      }
    };
  }

});